import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import {
  selectCurrentAccountId,
  selectCurrentPlatformId
} from "modules/common/selectors";
import { apiCall } from "modules/core";
import { selectMemberships } from "modules/platform";
import { normalize } from "normalizr";
import { AccountEntity, AccountsService } from "openapi";
import { accountSchema } from "store";
import { RootState } from "types";
import { NormalizedAccountEntity, PlainAccountEntity } from "../entities";
import { logout } from "./authentication-view.slice";
import { selectUserEntities } from "./user-entities.slice";

const { accountsControllerFindCurrent } = AccountsService;

export const findCurrentAccount = createAsyncThunk(
  "accounts/current",
  async (_, { rejectWithValue }) =>
    await apiCall(accountsControllerFindCurrent, rejectWithValue)
);

// Entity adapter
const entityAdapter = createEntityAdapter<PlainAccountEntity>({
  sortComparer: (entityA, entityB) => entityB.id - entityA.id
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "accountEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findCurrentAccount.fulfilled, (state, action) => {
      const normalizedData = normalize<
        AccountEntity,
        { accounts: Record<string, NormalizedAccountEntity> }
      >(action.payload, [accountSchema]);

      const [nextEntity] = Object.values(
        normalizedData.entities.accounts || []
      );

      entityAdapter.upsertOne(state, nextEntity);
    });
  }
});

export const accountEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectAccounts,
  selectEntities: selectAccountEntities,
  selectById: selectAccountById
} = entityAdapter.getSelectors((state: RootState) => state.accountEntities);

// Custom selector

// Account memberships selector
export const selectCurrentAccountMemberships = createSelector(
  selectCurrentPlatformId,
  selectCurrentAccountId,
  selectUserEntities,
  selectMemberships,
  (platformId, accountId, userEntities, memberships) => {
    if (!platformId || !accountId) return [];

    return memberships
      .filter(
        (membership) =>
          membership.platformId === platformId &&
          membership.accountId === accountId
      )
      .map((membership) => {
        const user = userEntities[membership.userId];
        if (!user) return null;

        return {
          id: user.id,
          name: user.name,
          phone: user.phone,
          role: membership.role,
          tags: membership.tags,
          userState: user.state,
          membershipState: membership.state
        };
      })
      .filter(Boolean);
  }
);
