import isEmpty from "lodash.isempty";
import { SpinnerLoader } from "modules/common/components";
import {
  selectCurrentAccount,
  selectCurrentUser
} from "modules/common/selectors";
import { useAppSelector } from "modules/core";
import { createContext, ReactNode, useContext } from "react";
import { PlainAccountEntity, PlainUserEntity } from "../entities";

interface State {
  account: PlainAccountEntity;
  user: PlainUserEntity;
}

const AuthContext = createContext<State | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("Auth context is invalid");
  }

  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const account = useAppSelector(selectCurrentAccount);
  const user = useAppSelector(selectCurrentUser);

  if (isEmpty(account) || isEmpty(user)) {
    return <SpinnerLoader />;
  }

  return (
    <AuthContext.Provider value={{ account, user }}>
      {children}
    </AuthContext.Provider>
  );
};
