import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { InstalledApps } from "modules/app";
import { TokensUsage } from "modules/usage";

export const Dashboard = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>

      <Grid container spacing={3}>
        {/* Usage Statistics */}
        <Grid size={{ xs: 12 }}>
          <TokensUsage />
        </Grid>

        {/* Installed Apps */}
        <Grid size={{ xs: 12 }}>
          <InstalledApps />
        </Grid>
      </Grid>
    </Box>
  );
};
