import { faExcavator } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  InputAdornment,
  Rating,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";

interface MarketplaceApp {
  id: string;
  name: string;
  description: string;
  developer: string;
  category: string;
  rating: number;
  reviews: number;
  icon: React.ReactNode;
  price: number | "Free";
}

const marketplaceApps: MarketplaceApp[] = [
  {
    id: "1",
    name: "ChatBot Pro",
    description:
      "Advanced chatbot with natural language processing capabilities",
    developer: "AI Solutions Inc.",
    category: "Communication",
    rating: 4.5,
    reviews: 128,
    icon: <ChatIcon sx={{ fontSize: 60, color: "primary.main" }} />,
    price: "Free"
  },
  {
    id: "2",
    name: "Data Analyzer",
    description: "Powerful data analysis and visualization tool",
    developer: "DataTech",
    category: "Analytics",
    rating: 4.8,
    reviews: 256,
    icon: <BarChartIcon sx={{ fontSize: 60, color: "secondary.main" }} />,
    price: 49
  },
  {
    id: "3",
    name: "AI Assistant",
    description: "AI-powered assistant for task automation",
    developer: "AutomateAI",
    category: "Productivity",
    rating: 4.2,
    reviews: 89,
    icon: <SmartToyIcon sx={{ fontSize: 60, color: "success.main" }} />,
    price: 29
  }
];

const categories = [
  "All",
  "Communication",
  "Analytics",
  "Productivity",
  "Security",
  "Integration"
];

export const Marketplace = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [openPublish, setOpenPublish] = useState(false);
  const [openInstall, setOpenInstall] = useState(false);
  const [selectedApp, setSelectedApp] = useState<MarketplaceApp | null>(null);

  const handleCategoryChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedCategory(newValue);
  };

  const handleInstall = (app: MarketplaceApp) => {
    setSelectedApp(app);
    setOpenInstall(true);
  };

  const handlePublishApp = () => {
    // Handle app publishing logic
    setOpenPublish(false);
  };

  const filteredApps = marketplaceApps.filter((app) => {
    const matchesSearch =
      app.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      app.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory =
      selectedCategory === 0 || app.category === categories[selectedCategory];
    return matchesSearch && matchesCategory;
  });

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">App Marketplace</Typography>

        {/* <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenPublish(true)}
        >
          Publish App
        </Button> */}
      </Box>

      <Typography fontSize={24} variant="h6" gutterBottom sx={{ mt: 4 }}>
        <FontAwesomeIcon icon={faExcavator} /> Under Construction
      </Typography>

      {/* Search and Filters */}
      {/* <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          placeholder="Search apps..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          sx={{ mb: 2 }}
        />
        <Tabs
          value={selectedCategory}
          onChange={handleCategoryChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {categories.map((category, index) => (
            <Tab key={category} label={category} value={index} />
          ))}
        </Tabs>
      </Box> */}

      {/* App Grid */}
      {/* <Grid container spacing={3}>
        {filteredApps.map((app) => (
          <Grid key={app.id} size={{ xs: 12, sm: 6, md: 4 }}>
            <Card>
              <Box
                sx={{
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "background.default"
                }}
              >
                {app.icon}
              </Box>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {app.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {app.description}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Rating
                    value={app.rating}
                    precision={0.5}
                    readOnly
                    size="small"
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 1 }}
                  >
                    ({app.reviews})
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  by {app.developer}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2
                  }}
                >
                  <Chip label={app.category} size="small" />
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography variant="h6" color="primary">
                      {typeof app.price === "number"
                        ? `$${app.price}`
                        : app.price}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleInstall(app)}
                    >
                      Install
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}

      {/* Publish App Dialog */}
      {/* <Dialog
        open={openPublish}
        onClose={() => setOpenPublish(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Publish Your App</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12 }}>
              <TextField fullWidth label="App Name" variant="outlined" />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={4}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField fullWidth label="Category" variant="outlined" select>
                {categories.slice(1).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Price"
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPublish(false)}>Cancel</Button>
          <Button variant="contained" onClick={handlePublishApp}>
            Publish
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Install App Dialog */}
      {/* <Dialog open={openInstall} onClose={() => setOpenInstall(false)}>
        <DialogTitle>Install {selectedApp?.name}</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            You are about to install {selectedApp?.name}. This app will be added
            to your platform.
          </Typography>
          {typeof selectedApp?.price === "number" && (
            <Typography variant="body2" color="text.secondary">
              A charge of ${selectedApp?.price} will be added to your next
              billing cycle.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInstall(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              // Handle installation
              setOpenInstall(false);
            }}
          >
            Confirm Installation
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};
