import { SubscriptionState } from "@monorepo/constants";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { logout } from "modules/authentication";
import { apiCall } from "modules/core";
import { normalize } from "normalizr";
import { MonetizationService, SubscribeDto, SubscriptionEntity } from "openapi";
import { subscriptionSchema } from "store";
import { RootState } from "types";
import {
  NormalizedSubscriptionEntity,
  PlainSubscriptionEntity,
  extractSubscriptionPlainData
} from "../entities";
import { selectCurrentPlan } from "./plans-entities.slice";

const { subscriptionsControllerFindAll, subscriptionsControllerSubscribe } =
  MonetizationService;

// Async thunks
export const findAllSubscriptions = createAsyncThunk(
  "subscriptions/findAll",
  async (_, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerFindAll, rejectWithValue)
);

export const subscribePlan = createAsyncThunk(
  "subscriptions/subscribe",
  async ({ body }: { body: SubscribeDto }, { rejectWithValue }) =>
    await apiCall(subscriptionsControllerSubscribe, rejectWithValue, body)
);

// Entity adapter
const entityAdapter = createEntityAdapter<PlainSubscriptionEntity>({
  sortComparer: (entityA, entityB) => entityB.id - entityA.id
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "subscriptionsEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findAllSubscriptions.fulfilled, (state, action) => {
      const normalizedData = normalize<
        SubscriptionEntity,
        {
          subscriptions: Record<string, NormalizedSubscriptionEntity>;
        }
      >(action.payload.data, [subscriptionSchema]);

      const nextEntities = Object.values(
        normalizedData.entities.subscriptions || []
      ).map(extractSubscriptionPlainData);

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const subscriptionsEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectSubscriptions,
  selectEntities: selectSubscriptionsEntities,
  selectById: selectSubscriptionById
} = entityAdapter.getSelectors(
  (state: RootState) => state.subscriptionsEntities
);

// Custom selector
export const selectCurrentSubscription = createSelector(
  selectSubscriptions,
  selectCurrentPlan,
  (subscriptions, currentPlan) => {
    if (currentPlan) {
      return subscriptions.find(
        (subscription) =>
          subscription.state === SubscriptionState.Active &&
          subscription.planId === currentPlan.id
      );
    }
  }
);
