import { useAppSelector } from "modules/core";
import { useMemo } from "react";
import { selectCurrentUser } from "../selectors";

const stringToColor = (string: string): string => {
  let hash = 0;

  // Using for...of for better string character iteration
  for (const char of string) {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }

  // Generate color using hash
  return `#${Array.from({ length: 3 })
    .map((_, i) => `00${((hash >> (i * 8)) & 0xff).toString(16)}`.slice(-2))
    .join("")}`;
};

const getInitials = (name: string): string => {
  const parts = name.trim().split(/\s+/);
  if (parts.length === 0) return "";
  if (parts.length === 1) {
    // Safely access the first character
    return parts[0].charAt(0).toUpperCase();
  }

  // Safely get first and last initials
  const firstInitial = parts[0].charAt(0).toUpperCase();
  const lastInitial = parts[parts.length - 1].charAt(0).toUpperCase();
  return `${firstInitial}${lastInitial}`;
};

export const useAvatar = () => {
  const currentUser = useAppSelector(selectCurrentUser);
  const name = currentUser?.name ?? "";

  return useMemo(() => {
    if (!name?.trim()) {
      return {};
    }

    return {
      sx: { bgcolor: stringToColor(name) },
      children: getInitials(name)
    };
  }, [name]);
};
