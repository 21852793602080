/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UserEntity } from '../models/UserEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UsersService {
    /**
     * Update an entity
     * @param requestBody
     * @returns UserEntity
     * @throws ApiError
     */
    public static usersControllerUpdate(
        requestBody: UpdateUserDto,
    ): CancelablePromise<UserEntity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
