import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useAppSelector } from "modules/core";
import { useState } from "react";
import { Plan } from "../components";
import {
  selectCurrentPlan,
  selectCurrentSubscription,
  selectPlans
} from "../slices";

interface Invoice {
  id: string;
  date: string;
  amount: number;
  status: string;
}

const invoices: Invoice[] = [
  {
    id: "INV-001",
    date: "2023-12-01",
    amount: 99,
    status: "Paid"
  },
  {
    id: "INV-002",
    date: "2023-11-01",
    amount: 99,
    status: "Paid"
  }
];

export const Billing = () => {
  const plans = useAppSelector(selectPlans);
  const currentPlan = useAppSelector(selectCurrentPlan);
  const currentSubscription = useAppSelector(selectCurrentSubscription);
  const [loading, setLoading] = useState(false);

  return (
    <PayPalScriptProvider
      deferLoading={true}
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
        components: "buttons",
        environment:
          process.env.NODE_ENV === "production" ? "production" : "sandbox",
        intent: "subscription",
        locale: "en_US",
        vault: true
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          Billing & Subscription
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Available Plans
        </Typography>
        <Grid container spacing={3}>
          {plans.map((plan) => (
            <Plan
              key={plan.id}
              plan={plan}
              currentPlan={currentPlan}
              currentSubscription={currentSubscription}
              loading={loading}
              setLoading={setLoading}
            />
          ))}
        </Grid>

        {/* Billing History */}
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Billing History
        </Typography>
        <Card>
          <CardContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices.map((invoice) => (
                    <TableRow key={invoice.id}>
                      <TableCell>{invoice.id}</TableCell>
                      <TableCell>
                        {new Date(invoice.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>${invoice.amount}</TableCell>
                      <TableCell>
                        <Chip
                          label={invoice.status}
                          color={
                            invoice.status === "Paid" ? "success" : "warning"
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          startIcon={<DownloadIcon />}
                          size="small"
                          onClick={() => {
                            // Handle download
                          }}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </PayPalScriptProvider>
  );
};
