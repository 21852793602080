import { faShieldCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Paper, Typography } from "@mui/material";
import { withWrapper } from "modules/core";

export const Privacy = withWrapper(() => {
  return (
    <Box sx={{ py: 4 }}>
      <Box sx={{ textAlign: "center", mb: 6 }}>
        <FontAwesomeIcon
          icon={faShieldCheck}
          style={{
            fontSize: "3rem",
            color: "primary.main",
            marginBottom: "1rem"
          }}
        />
        <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Your privacy is important to us
        </Typography>
      </Box>

      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography paragraph>
          MemoAI (Company, we, us, or our) is committed to protecting the
          privacy and security of the personal information of its users (Users,
          you, or your) who access our website at <a href="/">URL</a> (Site).
          This Privacy Policy describes how we collect, use, and disclose your
          personal information when you use our Site and the choices you have
          associated with that information.
        </Typography>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            Information We Collect
          </Typography>
          <Typography paragraph>
            We may collect personal information from you when you visit our
            Site, register on the Site, fill out a form, or engage in other
            activities on our Site. The personal information we collect may
            include your name, email address, mailing address, phone number,
            date of birth, medical history, health information, and other
            information you provide to us.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            How We Use Your Information
          </Typography>
          <Typography paragraph>
            We may use your personal information for the following purposes:
          </Typography>
          <Box component="ul" sx={{ pl: 4 }}>
            <Typography component="li">
              To provide you with access to our Site and its services.
            </Typography>
            <Typography component="li">
              To personalize your experience and improve our Site and services.
            </Typography>
            <Typography component="li">
              To communicate with you about our services, including sending you
              emails or other electronic communications.
            </Typography>
            <Typography component="li">
              To process transactions or payments for our services.
            </Typography>
            <Typography component="li">
              To comply with legal or regulatory requirements.
            </Typography>
            <Typography component="li">
              To protect our rights or interests, or those of others.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            How We Protect Your Information
          </Typography>
          <Typography paragraph>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure. However, no method of
            transmission over the internet or electronic storage is 100% secure.
            Therefore, we cannot guarantee absolute security of your personal
            information.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            Sharing Your Information
          </Typography>
          <Typography paragraph>
            We do not sell, trade, or rent your personal information to others.
            We may share your personal information with our third-party service
            providers who assist us in providing our services, such as hosting
            providers, payment processors, or customer service providers. These
            third-party service providers are required to protect your personal
            information and are not authorized to use or disclose your personal
            information for any other purpose.
          </Typography>
          <Typography paragraph>
            We may also share your personal information with third parties if we
            believe it is necessary to comply with legal or regulatory
            requirements, or to protect our rights or interests, or those of
            others.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            Third-Party Links
          </Typography>
          <Typography paragraph>
            Our Site may contain links to third-party websites, products, or
            services. These third-party websites have separate and independent
            privacy policies. We have no control over, and assume no
            responsibility for, the content, privacy policies, or practices of
            any third-party websites, products, or services.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            Your Rights and Choices
          </Typography>
          <Typography paragraph>
            You have certain rights and choices regarding your personal
            information, including the right to access, correct, or delete your
            personal information. You may also have the right to object to or
            restrict the processing of your personal information, or to request
            a copy of your personal information. To exercise any of these rights
            or choices, please contact us at [INSERT CONTACT INFORMATION].
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            Changes to this Privacy Policy
          </Typography>
          <Typography paragraph>
            We reserve the right to modify this Privacy Policy at any time. Any
            changes to this Privacy Policy will be posted on our Site and will
            become effective immediately upon posting. We encourage you to
            review this Privacy Policy periodically for any changes.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns about this Privacy Policy,
            please contact us.
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
});
