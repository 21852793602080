import { createSelector } from "reselect";
import { RootState } from "types";

// Custom selector
const selectAccountEntities = (state: RootState) =>
  state.accountEntities.entities;

const selectAuthenticationView = (state: RootState) => state.authenticationView;

const selectPlatformEntities = (state: RootState) =>
  state.platformEntities.entities;

const selectUserEntities = (state: RootState) => state.userEntities.entities;

export const selectCurrentPlatformId = (state: RootState) =>
  selectAuthenticationView(state).platformId;

export const selectCurrentPlatform = createSelector(
  selectPlatformEntities,
  selectCurrentPlatformId,
  (platformEntities, platformId) =>
    platformId ? platformEntities[platformId] : undefined
);

export const selectCurrentAccountId = (state: RootState) =>
  selectAuthenticationView(state).accountId;

export const selectCurrentAccount = createSelector(
  selectAccountEntities,
  selectCurrentAccountId,
  (accountEntities, accountId) =>
    accountId ? accountEntities[accountId] : undefined
);

export const selectCurrentUserId = (state: RootState) =>
  selectAuthenticationView(state).userId;

export const selectCurrentUser = createSelector(
  selectUserEntities,
  selectCurrentUserId,
  (userEntities, userId) => (userId ? userEntities[userId] : undefined)
);

export const selectAccessToken = (state: RootState) =>
  selectAuthenticationView(state).accessToken;

export const selectIsLoggedIn = (state: RootState) =>
  selectAuthenticationView(state).isLoggedIn;
