import {
  faClock,
  faClockRotateLeft,
  faGlobe,
  faLanguage,
  faPhone,
  faUser
} from "@fortawesome/pro-duotone-svg-icons";
import { Locale, TimeZone } from "@monorepo/constants";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import {
  ProfileDropdownInputItem,
  ProfileViewItem,
  updateCurrentUser
} from "modules/authentication";
import { SpinnerLoader } from "modules/common/components";
import { selectCurrentUser } from "modules/common/selectors";
import { useAppDispatch, useAppSelector, withWrapper } from "modules/core";

export const Profile = withWrapper(() => {
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  if (!user) {
    return <SpinnerLoader />;
  }

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "PP");
  };

  return (
    <Box sx={{ py: 4 }}>
      <Box sx={{ mb: 6, textAlign: "center" }}>
        <Typography variant="h3" fontWeight={700}>
          Profile Information
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          View and manage your personal information and preferences
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProfileViewItem icon={faUser} label="Name" value={user.name} />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <ProfileViewItem icon={faPhone} label="Phone" value={user.phone} />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <ProfileDropdownInputItem
            icon={faGlobe}
            label="Time Zone"
            onChange={(timeZone) => dispatch(updateCurrentUser({ timeZone }))}
            value={user.timeZone}
            values={TimeZone}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <ProfileDropdownInputItem
            icon={faLanguage}
            label="Locale"
            onChange={(locale) => dispatch(updateCurrentUser({ locale }))}
            value={user.locale}
            values={Locale}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <ProfileViewItem
            icon={faClock}
            label="Created At"
            value={formatDate(user.createdAt)}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <ProfileViewItem
            icon={faClockRotateLeft}
            label="Last Updated"
            value={formatDate(user.updatedAt)}
          />
        </Grid>
      </Grid>
    </Box>
  );
});
