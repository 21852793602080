import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "modules/core";
import { selectCurrentPlanLimitations } from "modules/monetization";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { selectTokenUsagesByMonth } from "../slices";

export const TokensUsage = () => {
  const theme = useTheme();
  const { maxTokens } = useAppSelector(selectCurrentPlanLimitations);
  const tokenUsagesByMonth = useAppSelector(selectTokenUsagesByMonth);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Tokens Usage Over Time
        </Typography>
        <Box sx={{ height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={tokenUsagesByMonth}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />

              <ReferenceLine
                y={maxTokens}
                stroke={theme.palette.error.main}
                strokeDasharray="3 3"
                label="Max Tokens"
              />

              <Bar dataKey="tokens" name="Tokens">
                {tokenUsagesByMonth.map((entry, index) => {
                  // Decide the bar color based on usage
                  let barColor = theme.palette.success.light;

                  // Limit exceeded
                  if (entry.tokens > maxTokens) {
                    barColor = theme.palette.error.main;
                  }

                  // Warning limit
                  else if (entry.tokens > maxTokens * 0.8) {
                    barColor = theme.palette.warning.main;
                  }

                  return <Cell key={`cell-${index}`} fill={barColor} />;
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};
