import { alpha, Box, GlobalStyles, useTheme } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const SharedLayout = ({ children }: Props) => {
  const theme = useTheme();
  const backgroundColor = alpha(theme.palette.primary.main, 0.1);

  return (
    <>
      <GlobalStyles styles={{ html: { backgroundColor } }} />
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor
        }}
      >
        {children}
      </Box>
    </>
  );
};
