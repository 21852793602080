import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { apiCall } from "modules/core";
import { normalize } from "normalizr";
import { PlatformEntity, PlatformsService } from "openapi";
import { platformSchema } from "store";
import { RootState } from "types/store";
import {
  extractPlatformPlainData,
  NormalizedPlatformEntity,
  PlainPlatformEntity
} from "../entities";

const { platformsControllerFindAll } = PlatformsService;

export const findAllPlatforms = createAsyncThunk(
  "platforms/findAll",
  async (_, { rejectWithValue }) =>
    await apiCall(platformsControllerFindAll, rejectWithValue)
);

// Entity adapter
const entityAdapter = createEntityAdapter<PlainPlatformEntity>({
  sortComparer: (entityA, entityB) => entityA.name.localeCompare(entityB.name)
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "platformEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findAllPlatforms.fulfilled, (state, action) => {
      const normalizedData = normalize<
        PlatformEntity,
        { platforms: Record<string, NormalizedPlatformEntity> }
      >(action.payload.data, [platformSchema]);

      const nextEntities = Object.values(
        normalizedData.entities.platforms || []
      ).map(extractPlatformPlainData);

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const platformEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectPlatforms,
  selectEntities: selectPlatformEntities,
  selectById: selectPlatformById
} = entityAdapter.getSelectors((state: RootState) => state.platformEntities);

// Custom selector
export const selectPlatformBySlug = createSelector(
  selectPlatforms,
  (_: RootState, slug: string) => slug,
  (platforms, slug) => platforms.find((platform) => platform.slug === slug)
);
