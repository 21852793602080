import { StatusCodes } from "http-status-codes";
import { routesGetter, startupScripts } from "modules";
import { initAuthentication, logout } from "modules/authentication";
import { SpinnerLoader } from "modules/common/components";
import { selectIsLoggedIn } from "modules/common/selectors";
import {
  ApiErrorBar,
  useAppDispatch,
  useAppSelector,
  useScrollToTop
} from "modules/core";
import { Suspense, useEffect, useMemo } from "react";
import { useRoutes } from "react-router-dom";

const AppContent = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeApp = async () => {
      try {
        dispatch(initAuthentication());
        await dispatch(startupScripts.load(isLoggedIn));
      } catch (error) {
        console.error("Error initializing app", error);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((error as any).statusCode === StatusCodes.BAD_REQUEST) {
          return;
        }

        dispatch(logout());
      }
    };

    initializeApp();
  }, [isLoggedIn]);

  const routes = useMemo(() => routesGetter.get(isLoggedIn), [isLoggedIn]);

  return useRoutes(routes);
};

const App = () => {
  useScrollToTop();

  return (
    <Suspense fallback={<SpinnerLoader />}>
      <AppContent />
      <ApiErrorBar />
    </Suspense>
  );
};

export default App;
