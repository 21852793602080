import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alpha, Box, Typography, useTheme } from "@mui/material";
import styles from "./ProfileItem.module.scss";

interface Props {
  icon: IconProp;
  label: string;
  children: React.ReactNode;
}

export const ProfileItem = ({ icon, label, children }: Props) => {
  const theme = useTheme();

  return (
    <Box
      className={styles.container}
      sx={{
        backgroundColor: alpha(theme.palette.background.paper, 0.8),
        "&:hover": { boxShadow: theme.shadows[4] }
      }}
    >
      <Box className={styles.content}>
        <Box
          className={styles.iconWrapper}
          sx={{
            background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
            boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            className={styles.icon}
            style={{ color: theme.palette.primary.contrastText }}
          />
        </Box>
        <Box className={styles.textContent} sx={{ flex: 1 }}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            gutterBottom
            className={styles.label}
          >
            {label}
          </Typography>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
