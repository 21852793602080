import { ApiError } from "openapi";

type Listener = (error: ApiError) => void;

const listeners: Listener[] = [];

export const setApiErrorListener = (listener: Listener) => {
  listeners.push(listener);
};

export const apiErrorHandler = (error: ApiError) => {
  listeners.forEach((listener) => listener(error));
};
