import {
  faCreditCard,
  faGaugeHigh,
  faGridDividers,
  faStore,
  faUser
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  alpha,
  Box,
  Container,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { SharedLayout } from "./SharedLayout";

const DRAWER_WIDTH = 240;

const menuItems = [
  { text: "Dashboard", icon: faGaugeHigh, path: "" },
  { text: "Account", icon: faUser, path: "account" },
  { text: "Billing", icon: faCreditCard, path: "billing" },
  { text: "My Apps", icon: faGridDividers, path: "my-apps" },
  { text: "Marketplace", icon: faStore, path: "marketplace" }
];

export const PlatformLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <SharedLayout>
      <CssBaseline />
      <Header />
      <Container maxWidth="xl">
        <Drawer
          variant="permanent"
          sx={{
            [`& .MuiDrawer-paper`]: {
              background: "inherit",
              left: "auto",
              width: DRAWER_WIDTH,
              zIndex: 0
            }
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {menuItems.map((item) => (
                <ListItem
                  key={item.text}
                  onClick={() => navigate(item.path)}
                  sx={{
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      cursor: "pointer"
                    }
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={item.icon} size="xl" />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Footer />
        </Drawer>
        <Box sx={{ p: 4, marginLeft: `${DRAWER_WIDTH}px` }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Container>
    </SharedLayout>
  );
};
