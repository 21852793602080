export enum Language {
  Afrikaans = "Afrikaans",
  Arabic = "Arabic",
  Armenian = "Armenian",
  Azerbaijani = "Azerbaijani",
  Belarusian = "Belarusian",
  Bosnian = "Bosnian",
  Bulgarian = "Bulgarian",
  Catalan = "Catalan",
  Chinese = "Chinese",
  Croatian = "Croatian",
  Czech = "Czech",
  Danish = "Danish",
  Dutch = "Dutch",
  English = "English",
  Estonian = "Estonian",
  Finnish = "Finnish",
  French = "French",
  Galician = "Galician",
  German = "German",
  Greek = "Greek",
  Hebrew = "Hebrew",
  Hindi = "Hindi",
  Hungarian = "Hungarian",
  Icelandic = "Icelandic",
  Indonesian = "Indonesian",
  Italian = "Italian",
  Japanese = "Japanese",
  Kannada = "Kannada",
  Kazakh = "Kazakh",
  Korean = "Korean",
  Latvian = "Latvian",
  Lithuanian = "Lithuanian",
  Macedonian = "Macedonian",
  Malay = "Malay",
  Maori = "Maori",
  Marathi = "Marathi",
  Nepali = "Nepali",
  Norwegian = "Norwegian",
  Persian = "Persian",
  Polish = "Polish",
  Portuguese = "Portuguese",
  Romanian = "Romanian",
  Russian = "Russian",
  Serbian = "Serbian",
  Slovak = "Slovak",
  Slovenian = "Slovenian",
  Spanish = "Spanish",
  Swahili = "Swahili",
  Swedish = "Swedish",
  Tagalog = "Tagalog",
  Tamil = "Tamil",
  Thai = "Thai",
  Turkish = "Turkish",
  Ukrainian = "Ukrainian",
  Urdu = "Urdu",
  Vietnamese = "Vietnamese",
  Welsh = "Welsh",
}
