export const zIndexLevels = {
  level1e0: 1,
  level1e1: 10,
  level1e2: 100,
  level1e3: 1000,
  level1e4: 10000,
  level1e5: 100000,
  level1e6: 1000000,
  level1e7: 10000000,
};
