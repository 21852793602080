import { Box, Button, Container, Typography } from "@mui/material";
import { withWrapper } from "modules/core";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  message: string;
  children: ReactNode;
}

export const Error = withWrapper(({ message, children }: Props) => {
  return (
    <Box sx={{ my: 5, pt: 5 }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: "center", mb: 5 }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "4rem", md: "6rem" },
              fontWeight: 500,
              mb: 2
            }}
          >
            {children}
          </Typography>

          <Typography variant="h4" textTransform="uppercase" sx={{ mb: 5 }}>
            {message}
          </Typography>

          <Button component={Link} to="/" variant="contained" color="primary">
            Back home
          </Button>
        </Box>
      </Container>
    </Box>
  );
});
