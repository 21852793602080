import { SubscriptionEntity } from "openapi";

export interface NormalizedSubscriptionEntity
  extends Omit<SubscriptionEntity, "plan"> {
  plan: number;
}

export interface PlainSubscriptionEntity
  extends Omit<NormalizedSubscriptionEntity, "plan"> {}

export const extractSubscriptionPlainData = ({
  plan,
  ...subscription
}: NormalizedSubscriptionEntity): PlainSubscriptionEntity => subscription;
