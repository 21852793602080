/* eslint-disable @typescript-eslint/no-unused-vars */
import { MembershipEntity, UserEntity } from "openapi";

export interface NormalizedMembershipEntity
  extends Omit<MembershipEntity, "platform" | "account" | "user"> {
  platform: number;
  account: number;
  user: number;
}

export interface PlainMembershipEntity
  extends Omit<NormalizedMembershipEntity, "platform" | "account" | "user"> {}

export const extractMembershipPlainData = ({
  platform,
  account,
  user,
  ...membership
}: NormalizedMembershipEntity): PlainMembershipEntity => membership;
