import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { apiCall } from "modules/core";
import {
  extractMembershipPlainData,
  NormalizedMembershipEntity
} from "modules/platform";
import { normalize } from "normalizr";
import {
  AuthenticationService,
  LoginUserOTPRequestDto,
  LoginUserRequestDto,
  MembershipEntity,
  OpenAPI
} from "openapi";
import { accountSchema } from "store";
import { RootState } from "types/store";
import { findCurrentAccount } from "./account-entities.slice";

interface JwtPayload {
  platformId?: number;
  accountId?: number;
  userId?: number;
  membershipId?: number;
}

interface State {
  accessToken?: string;
  isLoggedIn: boolean;
  platformId?: number;
  accountId?: number;
  userId?: number;
  membershipId?: number;
}

const { authenticationControllerLogin, authenticationControllerLoginOtp } =
  AuthenticationService;

export const logInAction = createAsyncThunk(
  "authentication/logIn",
  async (body: LoginUserRequestDto, { rejectWithValue }) =>
    await apiCall(authenticationControllerLogin, rejectWithValue, body)
);

export const logInOtpAction = createAsyncThunk(
  "authentication/logInOtp",
  async (body: LoginUserOTPRequestDto, { rejectWithValue }) =>
    await apiCall(authenticationControllerLoginOtp, rejectWithValue, body)
);

const setAuthenticationState = (state: State, accessToken?: string) => {
  const isLoggedIn = !!accessToken;
  state.isLoggedIn = isLoggedIn;
  state.accessToken = accessToken;

  const { membershipId } = accessToken
    ? jwtDecode<JwtPayload>(accessToken)
    : ({} as JwtPayload);

  state.membershipId = membershipId;
  state.platformId = undefined;
  state.accountId = undefined;
  state.userId = undefined;

  if (isLoggedIn) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
  }

  OpenAPI.TOKEN = accessToken;
};

const initialState: State = {
  isLoggedIn: false
};

const accessToken = localStorage.getItem("accessToken") || undefined;
setAuthenticationState(initialState, accessToken);

// Define the slice
const slice = createSlice({
  name: "authenticationView",
  initialState,
  reducers: {
    initAuthentication: (state) => {
      const { accessToken } = state;
      setAuthenticationState(state, accessToken);
    },
    logout: (state) => setAuthenticationState(state)
  },
  extraReducers: (builder) => {
    builder.addCase(logInOtpAction.fulfilled, (state, action) => {
      const { accessToken } = action.payload;
      setAuthenticationState(state, accessToken);
    });

    builder.addCase(findCurrentAccount.fulfilled, (state, action) => {
      const normalizedData = normalize<
        MembershipEntity,
        { memberships: Record<string, NormalizedMembershipEntity> }
      >(action.payload, [accountSchema]);

      const [nextEntity] = Object.values(
        normalizedData.entities.memberships || []
      ).map(extractMembershipPlainData);

      state.platformId = nextEntity.platformId;
      state.accountId = nextEntity.accountId;
      state.userId = nextEntity.userId;
    });
  }
});

export const authenticationViewReducer = slice.reducer;

// Export reducers
export const { initAuthentication, logout } = slice.actions;

// Custom selector
