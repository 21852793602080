import { selectCurrentPlatform } from "modules/common/selectors";
import { useAppSelector } from "modules/core";
import { createContext, ReactNode, useContext } from "react";
import { PlainPlatformEntity } from "../entities";

interface State {
  platform?: PlainPlatformEntity;
}

const initialState: State = {};

const PlatformContext = createContext(initialState);

export const usePlatform = () => useContext(PlatformContext);

export const PlatformProvider = ({ children }: { children: ReactNode }) => {
  const platform = useAppSelector(selectCurrentPlatform);

  return (
    <PlatformContext.Provider value={{ platform }}>
      {children}
    </PlatformContext.Provider>
  );
};
