import {
  IconButton,
  Menu,
  MenuItem,
  Avatar as MuiAvatar,
  Tooltip,
  Typography
} from "@mui/material";
import { useAvatar } from "modules/common/hooks";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";

const userPages = [
  { name: "Profile", to: "/profile" },
  { name: "LogOut", to: "/auth/logOut" }
];

export const Avatar = () => {
  const avatarProps = useAvatar();
  const [anchorElUser, setAnchorElUser] = useState<HTMLElement>();

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);

  const handleCloseUserMenu = () => setAnchorElUser(undefined);

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <MuiAvatar {...avatarProps} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "48px" }}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {userPages.map((page) => (
          <MenuItem
            key={page.name}
            component={Link}
            to={page.to}
            onClick={handleCloseUserMenu}
          >
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
