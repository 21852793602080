type NestedStringEnum = {
  [key: string]: string | NestedStringEnum;
};

type PrefixedNestedEnum<T extends NestedStringEnum> = {
  [K in keyof T]: T[K] extends string
    ? string
    : PrefixedNestedEnum<Extract<T[K], NestedStringEnum>>;
};

function prefixNestedEnum<T extends NestedStringEnum>(
  prefix: string,
  enumObj: T
): PrefixedNestedEnum<T> {
  return new Proxy(enumObj, {
    get(target, prop: string) {
      const value = target[prop];
      if (typeof value === "string") {
        return `${prefix}.${value}`;
      }

      const nextProp = `${prop.charAt(0).toLowerCase()}${prop.slice(1)}`;

      return prefixNestedEnum(
        `${prefix}.${nextProp}`,
        value as NestedStringEnum
      );
    }
  }) as PrefixedNestedEnum<T>;
}

const Reminder = {
  OneTime: {
    Schedule: "schedule",
    Cancel: "cancel"
  },
  Recurring: {
    Schedule: "schedule",
    Cancel: "cancel"
  },
  Assign: "assign",
  AssignTo: "assignTo",
  Cancel: "cancel",
  Confirm: "confirm",
  ScheduleSnooze: "scheduleSnooze",
  Snooze: "snooze"
};

const WhatsApp = {
  Send: "send"
};

export const Event = {
  Reminder: prefixNestedEnum("reminder", Reminder),
  WhatsApp: prefixNestedEnum("whatsApp", WhatsApp)
};
