import { AccountTier } from "@monorepo/constants";
import { Chip } from "@mui/material";
import { differenceInDays } from "date-fns";
import { useMemo } from "react";
import { PlainPlanEntity, PlainSubscriptionEntity } from "../entities";

interface Props {
  currentPlan: PlainPlanEntity;
  currentSubscription: PlainSubscriptionEntity;
}

export const DaysLeft = ({ currentPlan, currentSubscription }: Props) => {
  const daysLeft = useMemo(() => {
    const now = new Date();
    return (
      currentPlan.limitations.trialDays -
      differenceInDays(now, currentSubscription.startDate)
    );
  }, [currentPlan, currentSubscription]);

  if (currentPlan.tier !== AccountTier.Trial || daysLeft <= 0) {
    return null;
  }

  return (
    <Chip
      label={`${daysLeft} days left`}
      color="warning"
      size="small"
      sx={{
        position: "absolute",
        right: 8,
        top: 72
      }}
    />
  );
};
