import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { useMemo } from "react";
import { ProfileItem } from "./ProfileItem";

interface Props<T extends string> {
  icon: IconProp;
  label: string;
  onChange: (value: T) => void;
  value: T;
  values: Record<string, string>;
}

export const ProfileDropdownInputItem = <T extends string>({
  icon,
  label,
  onChange,
  value,
  values
}: Props<T>) => {
  const options = useMemo(
    () =>
      Object.values(values).map((value) => ({
        value,
        label: value
      })),
    [values]
  );

  const handleChange = (event: SelectChangeEvent<T>) => {
    onChange(event.target.value as T);
  };

  return (
    <ProfileItem icon={icon} label={label}>
      <FormControl fullWidth size="small">
        <Select
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiSelect-select": {
              py: 0.5,
              backgroundColor: "transparent"
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ProfileItem>
  );
};
