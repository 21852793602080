import { schema } from "normalizr";

// App
const appSchema = new schema.Entity("apps");
const platformAppSchema = new schema.Entity("platformApps", { app: appSchema });
appSchema.define({ platformApps: [platformAppSchema] });

// Authentication
const platformSchema = new schema.Entity("platforms");

const accountSchema = new schema.Entity("accounts");

const userSchema = new schema.Entity("users");

const membershipSchema = new schema.Entity("memberships", {
  platform: platformSchema,
  account: accountSchema,
  user: userSchema
});

platformSchema.define({ memberships: [membershipSchema] });
platformSchema.define({ platformApps: [platformAppSchema] });
platformAppSchema.define({ platform: platformSchema });

accountSchema.define({ memberships: [membershipSchema] });
userSchema.define({ memberships: [membershipSchema] });

// Monetization
const planSchema = new schema.Entity("plans");
platformSchema.define({ plans: [planSchema] });

const subscriptionSchema = new schema.Entity("subscriptions", {
  plan: planSchema
});
planSchema.define({ subscriptions: [subscriptionSchema] });

// Usage
const platformUsageSchema = new schema.Entity("platformUsages");

export {
  accountSchema,
  appSchema,
  membershipSchema,
  planSchema,
  platformAppSchema,
  platformSchema,
  platformUsageSchema,
  subscriptionSchema,
  userSchema
};
