import { Container, CssBaseline, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { SharedLayout } from "./SharedLayout";

export const PublicLayout = () => {
  return (
    <SharedLayout>
      <CssBaseline />
      <Header />
      <Container maxWidth="lg">
        <Toolbar />
        <Outlet />
        <Footer />
      </Container>
    </SharedLayout>
  );
};
