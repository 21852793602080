import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { useAppSelector } from "modules/core";
import { useState } from "react";
import { selectCurrentAccountMemberships } from "../slices";
import { AccountMember } from "../types";

export const Account = () => {
  const [openInvite, setOpenInvite] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AccountMember | null>(null);

  const members = useAppSelector(selectCurrentAccountMemberships);

  const handleInvite = () => {
    setOpenInvite(false);
    // Handle invite logic
  };

  const handleEdit = (user: AccountMember) => {
    setSelectedUser(user);
    setOpenEdit(true);
  };

  const handleDelete = (userId: number) => {
    // Handle delete logic
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">Account Management</Typography>
        <Button
          disabled
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={() => setOpenInvite(true)}
        >
          Invite User
        </Button>
      </Box>

      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Team Members
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell>User State</TableCell>
                  <TableCell>Membership State</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map(
                  (member) =>
                    member && (
                      <TableRow key={member.id}>
                        <TableCell>{member.name}</TableCell>
                        <TableCell>{member.phone}</TableCell>
                        <TableCell>{member.role}</TableCell>
                        <TableCell>
                          {member.tags.map((tag) => (
                            <Chip
                              key={tag}
                              label={tag}
                              size="small"
                              sx={{ mr: 0.5 }}
                            />
                          ))}
                        </TableCell>
                        <TableCell>{member.userState}</TableCell>
                        <TableCell>{member.membershipState}</TableCell>
                        <TableCell>
                          <IconButton
                            disabled
                            size="small"
                            onClick={() => handleEdit(member)}
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            disabled
                            size="small"
                            onClick={() => handleDelete(member.id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Invite User Dialog */}

      <Dialog open={openInvite} onClose={() => setOpenInvite(false)}>
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Email Address"
                type="email"
                variant="outlined"
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Select fullWidth label="Role" defaultValue="User">
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="User">User</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInvite(false)}>Cancel</Button>
          <Button onClick={handleInvite} variant="contained">
            Send Invitation
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Dialog */}
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                label="Name"
                defaultValue={selectedUser?.name}
                variant="outlined"
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Select
                fullWidth
                label="Role"
                defaultValue={selectedUser?.role || "User"}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="User">User</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEdit(false)}>Cancel</Button>
          <Button onClick={() => setOpenEdit(false)} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
