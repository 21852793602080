import { Marketplace, MyApps } from "modules/app";
import {
  Account,
  AuthProvider,
  LogIn,
  LogOut,
  Profile
} from "modules/authentication";
import { PlatformLayout, PublicLayout } from "modules/common/components";
import { Home, NotFound, Privacy, ServerError, Terms } from "modules/core";
import { Billing } from "modules/monetization";
import { Dashboard, PlatformProvider } from "modules/platform";
import { Navigate } from "react-router-dom";

const authRoutes = [
  // authentication
  { path: "/profile", element: <Profile /> }
];

const platformRoutes = [
  { path: "", element: <Dashboard /> },
  { path: "account", element: <Account /> },
  { path: "billing", element: <Billing /> },
  { path: "my-apps", element: <MyApps /> },
  { path: "marketplace", element: <Marketplace /> }
];

const publicRoutes = [
  { path: "/", element: <Home /> },

  // authentication
  { path: "auth/logIn", element: <LogIn /> },
  { path: "auth/logOut", element: <LogOut /> },

  // trust
  { path: "trust/privacy", element: <Privacy /> },
  { path: "trust/terms", element: <Terms /> },

  // error pages
  { path: "4xx-error", element: <NotFound /> },
  { path: "5xx-error", element: <ServerError /> },

  // not found routes
  { path: "*", element: <NotFound /> }
];

const get = (isLoggedIn: boolean) => [
  {
    path: "/",
    element: (
      <PlatformProvider>
        <PublicLayout />
      </PlatformProvider>
    ),

    children: [
      ...publicRoutes,
      ...authRoutes.map((route) => ({
        ...route,
        element: isLoggedIn ? (
          route.element
        ) : (
          <Navigate to="/auth/logIn" replace />
        )
      }))
    ]
  },
  {
    path: "/platforms/:slug",
    element: (
      <PlatformProvider>
        <PlatformLayout />
      </PlatformProvider>
    ),
    children: [
      ...platformRoutes.map((route) => ({
        ...route,
        element: isLoggedIn ? (
          <AuthProvider>{route.element}</AuthProvider>
        ) : (
          <Navigate to="/auth/logIn" replace />
        )
      }))
    ]
  }
];

export { get };
