import { Box, Toolbar } from "@mui/material";
import { withWrapper } from "modules/core";
import { PlatformSwitcher } from "modules/platform";
import { Avatar } from "../Avatar";

export const UserHeader = withWrapper(() => {
  return (
    <Toolbar disableGutters>
      <PlatformSwitcher />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "flex-end"
        }}
      >
        <Avatar />
      </Box>
    </Toolbar>
  );
});
