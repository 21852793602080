import { StatusCodes } from "http-status-codes";
import { ApiError } from "openapi";
import { apiErrorHandler } from "../listeners";

export const apiCall = async function <T, Args extends any[]>(
  apiFunction: (...args: Args) => Promise<T>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  rejectWithValue: Function | null,
  ...params: Args
): Promise<T> {
  try {
    const response = await apiFunction(...params);
    return response;
  } catch (error) {
    if (rejectWithValue) {
      if (error instanceof TypeError) {
        return rejectWithValue({ statusCode: StatusCodes.BAD_REQUEST });
      }

      apiErrorHandler(error as ApiError);
      return rejectWithValue((error as ApiError).body);
    }

    throw error;
  }
};
