import { logout } from "modules/authentication";
import { useAppDispatch, withWrapper } from "modules/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LogOut = withWrapper(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    navigate("/");
  }, [navigate]);

  return <></>;
});
