import { Alert, Portal, Snackbar } from "@mui/material";
import { ApiError } from "openapi";
import { useEffect, useState } from "react";
import { setApiErrorListener } from "../listeners";

export const ApiErrorBar = () => {
  const [error, setError] = useState<ApiError | undefined>();

  useEffect(() => {
    setApiErrorListener(setError);
  }, []);

  const onDismissSnackBar = () => setError(undefined);

  if (error) {
    const { body } = error;
    const { message = "Error" } = body || {};

    return (
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={onDismissSnackBar}
          open={!!error}
        >
          <Alert
            onClose={onDismissSnackBar}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    );
  }

  return null;
};
