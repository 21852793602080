import { AppDispatch } from "types/store";
import { findAllApps } from "./app";
import { findCurrentAccount } from "./authentication";
import { findAllSubscriptions } from "./monetization";
import { findAllPlatforms } from "./platform";
import { findAllPlatformUsages } from "./usage";

const load = (isLoggedIn: boolean) => async (dispatch: AppDispatch) => {
  if (isLoggedIn) {
    await Promise.all([
      dispatch(findCurrentAccount()).unwrap(),
      dispatch(findAllSubscriptions()).unwrap(),
      dispatch(findAllPlatforms()),
      dispatch(findAllPlatformUsages()),
      dispatch(findAllApps())
    ]);
  }
};

export { load };
