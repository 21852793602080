/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginUserOTPRequestDto } from '../models/LoginUserOTPRequestDto';
import type { LoginUserOTPResponseDto } from '../models/LoginUserOTPResponseDto';
import type { LoginUserRequestDto } from '../models/LoginUserRequestDto';
import type { LoginUserResponseDto } from '../models/LoginUserResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthenticationService {
    /**
     * @param requestBody
     * @returns LoginUserResponseDto
     * @throws ApiError
     */
    public static authenticationControllerLogin(
        requestBody: LoginUserRequestDto,
    ): CancelablePromise<LoginUserResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/authentication/logIn',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns LoginUserOTPResponseDto
     * @throws ApiError
     */
    public static authenticationControllerLoginOtp(
        requestBody: LoginUserOTPRequestDto,
    ): CancelablePromise<LoginUserOTPResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/authentication/logInOTP',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
