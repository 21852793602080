import {
  appEntitiesReducer as appEntities,
  platformAppEntitiesReducer as platformAppEntities
} from "modules/app";
import {
  accountEntitiesReducer as accountEntities,
  authenticationViewReducer as authenticationView,
  userEntitiesReducer as userEntities
} from "modules/authentication";
import {
  plansEntitiesReducer as plansEntities,
  subscriptionsEntitiesReducer as subscriptionsEntities
} from "modules/monetization";
import {
  membershipEntitiesReducer as membershipEntities,
  platformEntitiesReducer as platformEntities
} from "modules/platform";
import { platformUsageEntitiesReducer as platformUsageEntities } from "modules/usage";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  // Entities
  accountEntities,
  appEntities,
  membershipEntities,
  plansEntities,
  subscriptionsEntities,
  platformAppEntities,
  platformEntities,
  platformUsageEntities,
  userEntities,

  //Views
  authenticationView
});

export default rootReducer;
