import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { startOfMonth } from "date-fns";
import isEmpty from "lodash.isempty";
import { logout } from "modules/authentication";
import { selectCurrentPlatformId } from "modules/common/selectors";
import { selectCurrentPlanLimitations } from "modules/monetization";
import { findAllPlatforms } from "modules/platform";
import { selectAppUsages } from "modules/usage";
import { normalize } from "normalizr";
import { PlatformAppEntity } from "openapi";
import { platformSchema } from "store";
import { RootState } from "types";
import {
  NormalizedPlatformAppEntity,
  PlainPlatformAppEntity
} from "../entities";
import { InstalledApp } from "../types";
import { selectAppEntities } from "./app-entities.slice";

const EMPTY_ARRAY = [] as const;
const EMPTY_OBJECT = {} as const;

// Entity adapter
const entityAdapter = createEntityAdapter<PlainPlatformAppEntity>({
  sortComparer: (entityA, entityB) => entityB.id - entityA.id
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "platformAppEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findAllPlatforms.fulfilled, (state, action) => {
      const normalizedData = normalize<
        PlatformAppEntity,
        { platformApps: Record<string, NormalizedPlatformAppEntity> }
      >(action.payload.data, [platformSchema]);

      const nextEntities = Object.values(
        normalizedData.entities.platformApps || []
      );

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const platformAppEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectPlatformApps,
  selectEntities: selectPlatformAppEntities,
  selectById: selectPlatformAppById
} = entityAdapter.getSelectors((state: RootState) => state.platformAppEntities);

// Custom selector
export const selectPlatformAppsWithUsage = createSelector(
  selectCurrentPlatformId,
  selectCurrentPlanLimitations,
  selectAppEntities,
  selectPlatformApps,
  selectAppUsages,
  (platformId, planLimitations, appEntities, platformApps, appUsages) => {
    if (isEmpty(appEntities)) {
      return EMPTY_ARRAY;
    }

    const currentPlatformApps = platformApps.filter(
      (platformApp) => platformApp.platformId === platformId
    );

    const { maxAppUsages } = planLimitations;

    const startOfCurrentMonth = startOfMonth(new Date());

    return currentPlatformApps
      .map((platformApp): InstalledApp => {
        const app = appEntities[platformApp.appId] || EMPTY_OBJECT;

        const { name } = app;
        const { state } = platformApp;

        // Calculate usage for the current month
        const usages = appUsages.filter(
          (appUsage) =>
            appUsage.platformAppId === platformApp.id &&
            new Date(appUsage.createdAt) >= startOfCurrentMonth
        ).length;

        const usagePercentage = Math.min(
          Math.round((usages / maxAppUsages) * 100),
          100
        );

        return {
          name,
          // TODO: For now all apps are platform level
          type: "Platform",
          usage: usagePercentage,
          state
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }
);
