/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllPlatformsResponse } from '../models/AllPlatformsResponse';
import type { AllPlatformUsagesResponse } from '../models/AllPlatformUsagesResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PlatformsService {
    /**
     * Get all platforms
     * @returns AllPlatformsResponse
     * @throws ApiError
     */
    public static platformsControllerFindAll(): CancelablePromise<AllPlatformsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/platforms',
        });
    }
    /**
     * Get all platform usages
     * @returns AllPlatformUsagesResponse
     * @throws ApiError
     */
    public static platformUsagesControllerFindAll(): CancelablePromise<AllPlatformUsagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/platformUsages',
        });
    }
}
