import { Middleware } from "redux";

const EMPTY_OBJ = {};

const brainDataMiddleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { brainDataEvent } = action || EMPTY_OBJ;
    action;
    if (brainDataEvent) {
      console.log("brainDataEvent", brainDataEvent);
    }

    if (action) {
      return next(action);
    }
  };

export { brainDataMiddleware };
