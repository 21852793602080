/* eslint-disable react/no-unescaped-entities */
import {
  faBuilding,
  faCommentDots,
  faRobot,
  faUsers
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme
} from "@mui/material";
import { withWrapper } from "modules/core";
import { useMemo } from "react";

export const Home = withWrapper(() => {
  const theme = useTheme();

  const features = useMemo(
    () => [
      {
        icon: faCommentDots,
        title: "WhatsApp Integration",
        description:
          "Set reminders directly through WhatsApp with natural language. Our AI understands your intent and schedules accordingly."
      },
      {
        icon: faRobot,
        title: "AI-Powered Scheduling",
        description:
          "Smart context recognition for one-time or recurring reminders. The AI understands your needs and sets the right schedule type."
      },
      {
        icon: faUsers,
        title: "Collaborative Reminders",
        description:
          "Add team members to your account and manage reminders together. Perfect for families, teams, and organizations."
      },
      {
        icon: faBuilding,
        title: "Universal Platform Integration",
        description:
          "Connect with any platform using MCP integration. Extract and process information automatically from various sources."
      }
    ],
    []
  );

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,
          color: "white",
          py: 10,
          borderRadius: "0 0 30px 30px"
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                fontWeight="bold"
                sx={{ mb: 3 }}
              >
                AI-Powered Reminders via WhatsApp
              </Typography>
              <Typography variant="h5" paragraph sx={{ mb: 4 }}>
                Let AI handle your scheduling needs. Simply chat with our
                WhatsApp bot, and we'll take care of the rest. Perfect for teams
                and multi-platform integration.
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Paper
                  elevation={6}
                  sx={{
                    p: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(10px)",
                    borderRadius: 2,
                    width: "100%",
                    maxWidth: 400
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "white", fontFamily: "monospace" }}
                  >
                    👋 Hey MemoAI!
                    <br />
                    Remind me about team meeting every Monday at 10 AM
                    <br />
                    <br />
                    🤖 Got it! I'll set a recurring reminder for your team
                    meeting every Monday at 10 AM.
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography variant="h3" component="h2" textAlign="center" gutterBottom>
          Smart Features for Modern Teams
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          textAlign="center"
          sx={{ mb: 6 }}
        >
          Experience the power of AI-driven scheduling and team collaboration
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid key={index} size={{ xs: 12, sm: 6, md: 3 }}>
              <Paper
                elevation={2}
                sx={{
                  p: 4,
                  height: "100%",
                  borderRadius: 3,
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: 8
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  style={{
                    fontSize: "3.5rem",
                    color: theme.palette.primary.main,
                    marginBottom: "1.5rem"
                  }}
                />
                <Typography
                  variant="h5"
                  component="h3"
                  gutterBottom
                  fontWeight="bold"
                >
                  {feature.title}
                </Typography>
                <Typography color="text.secondary">
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* CTA Section */}
      <Box sx={{ py: 10, borderRadius: "30px 30px 0 0" }}>
        <Container maxWidth="md">
          <Paper
            elevation={3}
            sx={{
              p: 6,
              textAlign: "center",
              borderRadius: 4,
              background: `linear-gradient(45deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
            }}
          >
            <Typography variant="h3" component="h2" gutterBottom color="white">
              Ready to Revolutionize Your Reminders?
            </Typography>
            <Typography
              variant="h6"
              paragraph
              sx={{ color: "rgba(255, 255, 255, 0.9)", mb: 4 }}
            >
              Join thousands of users who trust MemoAI for intelligent
              scheduling across platforms.
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
});
