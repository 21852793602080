import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { apiCall } from "modules/core";
import { normalize } from "normalizr";
import { UpdateUserDto, UserEntity, UsersService } from "openapi";
import { accountSchema, userSchema } from "store";
import { RootState } from "types";
import { NormalizedUserEntity, PlainUserEntity } from "../entities";
import { findCurrentAccount } from "./account-entities.slice";
import { logout } from "./authentication-view.slice";

const { usersControllerUpdate } = UsersService;

export const updateCurrentUser = createAsyncThunk(
  "users/update",
  async (body: UpdateUserDto, { rejectWithValue }) =>
    await apiCall(usersControllerUpdate, rejectWithValue, body)
);

// Entity adapter
const entityAdapter = createEntityAdapter<PlainUserEntity>({
  sortComparer: (entityA, entityB) => entityB.id - entityA.id
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "userEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(updateCurrentUser.fulfilled, (state, action) => {
      const normalizedData = normalize<
        UserEntity,
        { users: Record<string, NormalizedUserEntity> }
      >(action.payload, userSchema);

      const [nextEntity] = Object.values(normalizedData.entities.users || []);
      entityAdapter.upsertOne(state, nextEntity);
    });

    builder.addCase(findCurrentAccount.fulfilled, (state, action) => {
      const normalizedData = normalize<
        UserEntity,
        { users: Record<string, NormalizedUserEntity> }
      >(action.payload, [accountSchema]);

      const nextEntities = Object.values(normalizedData.entities.users || []);

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const userEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectUsers,
  selectEntities: selectUserEntities,
  selectById: selectUserById
} = entityAdapter.getSelectors((state: RootState) => state.userEntities);

// Custom selector
