import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Typography } from "@mui/material";
import { ProfileItem } from "./ProfileItem";

interface Props {
  icon: IconProp;
  label: string;
  value: string;
}

export const ProfileViewItem = ({ icon, label, value }: Props) => {
  return (
    <ProfileItem icon={icon} label={label}>
      <Typography variant="body1" className="value">
        {value}
      </Typography>
    </ProfileItem>
  );
};
