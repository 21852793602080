import SmartToy from "@mui/icons-material/SmartToyTwoTone";
import { Box, Button, Toolbar, Typography } from "@mui/material";
import { withWrapper } from "modules/core";
import { Link } from "react-router-dom";

export const GuestHeader = withWrapper(() => {
  return (
    <Toolbar disableGutters>
      <Box sx={{ display: "flex", alignItems: "center", margin: 1 }}>
        <SmartToy sx={{ mr: 2 }} />
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none"
          }}
        >
          MemoAI
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "flex-end"
        }}
      >
        <Button
          component={Link}
          to="/auth/logIn"
          sx={{ color: "inherit", flexGrow: 0 }}
        >
          LogIn
        </Button>
      </Box>
    </Toolbar>
  );
});
