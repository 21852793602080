import { AccountTier } from "@monorepo/constants";
import { PlanLimitation } from "@monorepo/types";
import {
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { logout } from "modules/authentication";
import { selectCurrentAccount } from "modules/common/selectors";
import { findAllPlatforms } from "modules/platform";
import { normalize } from "normalizr";
import { PlanEntity } from "openapi";
import { platformSchema } from "store";
import { RootState } from "types";
import { NormalizedPlanEntity, PlainPlanEntity } from "../entities";

const EMPTY_OBJECT = {} as const;

// Async thunks

// Entity adapter
const tierOrderMap: Record<AccountTier, number> = {
  [AccountTier.Trial]: 0,
  [AccountTier.Basic]: 1,
  [AccountTier.Pro]: 2,
  [AccountTier.Business]: 3,
  [AccountTier.Enterprise]: 4
};

const entityAdapter = createEntityAdapter<PlainPlanEntity>({
  sortComparer: (entityA, entityB) =>
    tierOrderMap[entityA.tier] - tierOrderMap[entityB.tier]
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "plansEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findAllPlatforms.fulfilled, (state, action) => {
      const normalizedData = normalize<
        PlanEntity,
        { plans: Record<string, NormalizedPlanEntity> }
      >(action.payload.data, [platformSchema]);

      const nextEntities = Object.values(normalizedData.entities.plans || []);

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const plansEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectPlans,
  selectEntities: selectPlansEntities,
  selectById: selectPlanById
} = entityAdapter.getSelectors((state: RootState) => state.plansEntities);

// Custom selector
export const selectCurrentPlan = createSelector(
  selectPlans,
  selectCurrentAccount,
  (plans, account) => {
    if (account) {
      return plans.find((plan) => {
        return plan.tier === account.tier;
      });
    }
  }
);

export const selectCurrentPlanLimitations = (state: RootState) => {
  const currentPlan = selectCurrentPlan(state);

  return (currentPlan?.limitations || EMPTY_OBJECT) as PlanLimitation;
};
