/* eslint-disable react/no-unescaped-entities */
import { faScaleBalanced } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Paper, Typography } from "@mui/material";
import { withWrapper } from "modules/core";

export const Terms = withWrapper(() => {
  return (
    <Box sx={{ py: 4 }}>
      <Box sx={{ textAlign: "center", mb: 6 }}>
        <FontAwesomeIcon
          icon={faScaleBalanced}
          style={{
            fontSize: "3rem",
            color: "primary.main",
            marginBottom: "1rem"
          }}
        />
        <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Please read these terms carefully before using our service
        </Typography>
      </Box>

      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography paragraph>
          Welcome to MemoAI. By accessing or using our website and services, you
          agree to be bound by these Terms of Service. If you do not agree to
          these terms, please do not use our service.
        </Typography>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing and using MemoAI's services, you acknowledge that you
            have read, understood, and agree to be bound by these Terms of
            Service. These terms may be updated from time to time, and your
            continued use of the service constitutes acceptance of any changes.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            2. Service Description
          </Typography>
          <Typography paragraph>
            MemoAI provides an AI-powered platform for [description of your
            service]. Our service is provided "as is" and may be updated or
            modified at any time. We reserve the right to change, suspend, or
            discontinue any aspect of the service at any time without notice.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            3. User Accounts
          </Typography>
          <Typography paragraph>
            To access certain features of our service, you may need to create an
            account. You are responsible for maintaining the confidentiality of
            your account credentials and for all activities that occur under
            your account. You agree to notify us immediately of any unauthorized
            use of your account.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            4. User Responsibilities
          </Typography>
          <Typography paragraph>
            When using our service, you agree to:
          </Typography>
          <Box component="ul" sx={{ pl: 4 }}>
            <Typography component="li">
              Provide accurate and complete information
            </Typography>
            <Typography component="li">
              Use the service in compliance with all applicable laws
            </Typography>
            <Typography component="li">
              Not engage in any unauthorized or illegal activities
            </Typography>
            <Typography component="li">
              Not interfere with or disrupt the service or servers
            </Typography>
            <Typography component="li">
              Not attempt to gain unauthorized access to any part of the service
            </Typography>
          </Box>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            5. Intellectual Property
          </Typography>
          <Typography paragraph>
            All content, features, and functionality of our service, including
            but not limited to text, graphics, logos, and software, are owned by
            MemoAI or its licensors and are protected by intellectual property
            laws. You may not copy, modify, distribute, or create derivative
            works without our explicit permission.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            6. Limitation of Liability
          </Typography>
          <Typography paragraph>
            To the maximum extent permitted by law, MemoAI shall not be liable
            for any indirect, incidental, special, consequential, or punitive
            damages, or any loss of profits or revenues, whether incurred
            directly or indirectly, or any loss of data, use, goodwill, or other
            intangible losses resulting from your use or inability to use the
            service.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            7. Termination
          </Typography>
          <Typography paragraph>
            We reserve the right to terminate or suspend your access to our
            service immediately, without prior notice or liability, for any
            reason whatsoever, including without limitation if you breach these
            Terms of Service.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            8. Governing Law
          </Typography>
          <Typography paragraph>
            These Terms of Service shall be governed by and construed in
            accordance with the laws of [Your Jurisdiction], without regard to
            its conflict of law provisions.
          </Typography>
        </Box>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            gutterBottom
          >
            9. Contact Information
          </Typography>
          <Typography paragraph>
            If you have any questions about these Terms of Service, please
            contact us.
          </Typography>
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant="body2" color="text.secondary" align="center">
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
});
