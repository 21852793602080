export enum Subject {
  // App
  AppEntity = "AppEntity",
  PlatformAppEntity = "PlatformAppEntity",

  // Authentication
  AccountEntity = "AccountEntity",
  PlatformEntity = "PlatformEntity",
  UserEntity = "UserEntity",

  // Core
  FeedbackEntity = "FeedbackEntity",

  // Monetization
  PlanEntity = "PlanEntity",
  SubscriptionEntity = "SubscriptionEntity",
  SubscriptionPaymentEntity = "SubscriptionPaymentEntity",

  // Usage
  PlatformUsageEntity = "PlatformUsageEntity"
}
