import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { logout } from "modules/authentication";
import { apiCall } from "modules/core";
import { normalize } from "normalizr";
import { AppEntity, AppsService } from "openapi";
import { appSchema } from "store";
import { RootState } from "types";
import { NormalizedAppEntity, PlainAppEntity } from "../entities";

const { appsControllerFindAll } = AppsService;

export const findAllApps = createAsyncThunk(
  "apps/findAll",
  async (_, { rejectWithValue }) =>
    await apiCall(appsControllerFindAll, rejectWithValue)
);

// Entity adapter
const entityAdapter = createEntityAdapter<PlainAppEntity>({
  sortComparer: (entityA, entityB) => entityB.id - entityA.id
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "appEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findAllApps.fulfilled, (state, action) => {
      const normalizedData = normalize<
        AppEntity,
        { apps: Record<string, NormalizedAppEntity> }
      >(action.payload.data, [appSchema]);

      const nextEntities = Object.values(normalizedData.entities.apps || []);

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const appEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectApps,
  selectEntities: selectAppEntities,
  selectById: selectAppById
} = entityAdapter.getSelectors((state: RootState) => state.appEntities);

// Custom selector
