/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllSubscriptionsResponse } from '../models/AllSubscriptionsResponse';
import type { SubscribeDto } from '../models/SubscribeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MonetizationService {
    /**
     * Get all entities
     * @param where
     * @param order
     * @param relations
     * @param skip
     * @param take
     * @returns AllSubscriptionsResponse
     * @throws ApiError
     */
    public static subscriptionsControllerFindAll(
        where?: Record<string, any>,
        order?: Record<string, any>,
        relations?: Array<string>,
        skip?: number,
        take?: number,
    ): CancelablePromise<AllSubscriptionsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subscriptions',
            query: {
                'where': where,
                'order': order,
                'relations': relations,
                'skip': skip,
                'take': take,
            },
        });
    }
    /**
     * Subscribe to plan
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static subscriptionsControllerSubscribe(
        requestBody: SubscribeDto,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/subscriptions/subscribe',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static webhooksControllerPaypalEvent(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/webhooks/paypal',
        });
    }
}
