import { faExcavator } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { useState } from "react";

interface App {
  id: string;
  name: string;
  description: string;
  status: string;
  type: "Platform" | "Account";
  usage: {
    current: number;
    limit: number;
  };
}

const installedApps: App[] = [
  {
    id: "1",
    name: "ChatBot Pro",
    description:
      "Advanced chatbot with natural language processing capabilities",
    status: "Active",
    type: "Platform",
    usage: {
      current: 7500,
      limit: 10000
    }
  },
  {
    id: "2",
    name: "Data Analyzer",
    description: "Powerful data analysis and visualization tool",
    status: "Active",
    type: "Account",
    usage: {
      current: 4500,
      limit: 10000
    }
  },
  {
    id: "3",
    name: "AI Assistant",
    description: "AI-powered assistant for task automation",
    status: "Inactive",
    type: "Platform",
    usage: {
      current: 0,
      limit: 5000
    }
  }
];

export const MyApps = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedApp, setSelectedApp] = useState<App | null>(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, app: App) => {
    setAnchorEl(event.currentTarget);
    setSelectedApp(app);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsOpen = () => {
    handleMenuClose();
    setOpenSettings(true);
  };

  const handleDeleteOpen = () => {
    handleMenuClose();
    setOpenDelete(true);
  };

  const handleDeleteApp = () => {
    // Handle app deletion
    setOpenDelete(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        My Apps
      </Typography>

      <Typography fontSize={24} variant="h6" gutterBottom sx={{ mt: 4 }}>
        <FontAwesomeIcon icon={faExcavator} /> Under Construction
      </Typography>

      {/* <Grid container spacing={3}>
        {installedApps.map((app) => (
          <Grid key={app.id} size={{ xs: 12, md: 4 }}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2
                  }}
                >
                  <Typography variant="h6" component="div">
                    {app.name}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={(e) => handleMenuOpen(e, app)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
                <Typography color="text.secondary" sx={{ mb: 2 }}>
                  {app.description}
                </Typography>
                <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                  <Chip
                    label={app.status}
                    size="small"
                    color={app.status === "Active" ? "success" : "default"}
                  />
                  <Chip
                    label={app.type}
                    size="small"
                    color={app.type === "Platform" ? "primary" : "secondary"}
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Usage: {app.usage.current.toLocaleString()} /{" "}
                  {app.usage.limit.toLocaleString()} tokens
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  startIcon={<SettingsIcon />}
                  onClick={handleSettingsOpen}
                >
                  Settings
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid> */}

      {/* App Menu */}
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSettingsOpen}>
          <SettingsIcon sx={{ mr: 1 }} /> Settings
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen} sx={{ color: "error.main" }}>
          <DeleteIcon sx={{ mr: 1 }} /> Uninstall
        </MenuItem>
      </Menu> */}

      {/* Settings Dialog */}
      {/* <Dialog open={openSettings} onClose={() => setOpenSettings(false)}>
        <DialogTitle>App Settings - {selectedApp?.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Configure your app settings here. This is a placeholder for
            app-specific settings.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSettings(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => setOpenSettings(false)}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Delete Confirmation Dialog */}
      {/* <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Uninstall App</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to uninstall {selectedApp?.name}? This action
            cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handleDeleteApp}>
            Uninstall
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};
