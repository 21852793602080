import { AccountTier, PaymentVendor } from "@monorepo/constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid2 as Grid,
  Typography
} from "@mui/material";
import {
  DISPATCH_ACTION,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { useAppDispatch } from "modules/core";
import { useEffect, useMemo } from "react";
import { PlainPlanEntity, PlainSubscriptionEntity } from "../entities";
import { subscribePlan } from "../slices";
import { DaysLeft } from "./DaysLeft";

interface Props {
  plan: PlainPlanEntity;
  currentPlan?: PlainPlanEntity;
  currentSubscription?: PlainSubscriptionEntity;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const Plan = ({
  plan,
  currentPlan,
  currentSubscription,
  loading,
  setLoading
}: Props) => {
  const dispatch = useAppDispatch();
  const [{ options }, paypalDispatch] = usePayPalScriptReducer();

  const isCurrentPlan = plan.id === currentPlan?.id;
  const isTrialExpired = useMemo(() => {
    if (plan.tier === AccountTier.Trial) {
      if (currentPlan?.tier !== AccountTier.Trial) {
        return true;
      }
    }

    return false;
  }, [plan.tier, currentPlan?.tier]);

  useEffect(() => {
    paypalDispatch({
      type: DISPATCH_ACTION.RESET_OPTIONS,
      value: { ...options, intent: "subscription" }
    });
  }, []);

  return (
    <Grid key={plan.id} size={{ xs: 12, md: 4 }}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          ...(isCurrentPlan && {
            border: (theme) => `2px solid ${theme.palette.primary.main}`
          }),
          ...(isTrialExpired && { opacity: 0.5 })
        }}
      >
        {isCurrentPlan && (
          <>
            <Chip
              label="Current Plan"
              color="primary"
              size="small"
              sx={{
                position: "absolute",
                right: 8,
                top: 8
              }}
            />
            {currentSubscription && (
              <>
                <Chip
                  label={currentSubscription.state}
                  color="secondary"
                  size="small"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 40
                  }}
                />
                <DaysLeft
                  currentPlan={currentPlan}
                  currentSubscription={currentSubscription}
                />
              </>
            )}
          </>
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="h5" gutterBottom>
            {plan.tier}
          </Typography>
          {plan.priceAmount ? (
            <Typography variant="h4" color="primary" gutterBottom>
              ${plan.priceAmount}
              <Typography variant="caption" color="text.secondary">
                /month
              </Typography>
            </Typography>
          ) : (
            <Typography variant="h4" color="primary" gutterBottom>
              Free
            </Typography>
          )}
          <Box sx={{ mt: 2 }}>
            {plan.limitations.maxUsers && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.maxUsers} users
                </Typography>
              </Box>
            )}
            {plan.limitations.maxAppUsages && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.maxAppUsages.toLocaleString("en-US")} app
                  usages/month
                </Typography>
              </Box>
            )}
            {plan.limitations.maxTokens && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.maxTokens.toLocaleString("en-US")}{" "}
                  tokens/month
                </Typography>
              </Box>
            )}
            {plan.limitations.trialDays && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, fontSize: 20, color: "success.main" }}
                />
                <Typography variant="body2">
                  {plan.limitations.trialDays} days trial
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
        {Object.entries(plan.vendorPlans).map(([vendor, planId]) => (
          <Box key={vendor} sx={{ p: 2 }}>
            <PayPalButtons
              disabled={loading || isCurrentPlan}
              onClick={() => setLoading(true)}
              createSubscription={(_, actions) => {
                if (
                  currentSubscription &&
                  currentSubscription.vendorSubscriptionId
                ) {
                  return actions.subscription.revise(
                    currentSubscription.vendorSubscriptionId,
                    { plan_id: planId }
                  );
                }

                return actions.subscription.create({ plan_id: planId });
              }}
              onApprove={async (data, _) => {
                setLoading(false);
                dispatch(
                  subscribePlan({
                    body: {
                      vendor: PaymentVendor.PayPal,
                      tier: plan.tier,
                      orderID: data.orderID || "",
                      purchaseToken: data.facilitatorAccessToken || "",
                      vendorSubscriptionId: data.subscriptionID || ""
                    }
                  })
                );
              }}
              onCancel={() => setLoading(false)}
              onError={() => setLoading(false)}
              style={{ label: "subscribe" }}
            />
          </Box>
        ))}
      </Card>
    </Grid>
  );
};
