import {
  faCirclePlus,
  faGrid,
  faMessageBot
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmartToyTwoToneIcon from "@mui/icons-material/SmartToyTwoTone";
import { Box, Button, IconButton, Popover } from "@mui/material";
import { useAppSelector, withWrapper } from "modules/core";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { PlainPlatformEntity } from "../entities";
import { usePlatform } from "../providers";
import { selectPlatforms } from "../slices";

export const PlatformSwitcher = withWrapper(() => {
  const { platform: currentPlatform } = usePlatform();
  const platforms = useAppSelector(selectPlatforms);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePlatformClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (platform: PlainPlatformEntity) => {
      // TODO: Implement changePlatform
      // await dispatch(changePlatform(platform)).unwrap();
      handleClose();
    },
    []
  );

  if (!currentPlatform) {
    return null;
  }

  return (
    <div>
      <Box sx={{ alignItems: "center" }}>
        <IconButton
          variant="outlined"
          color="inherit"
          component={Button}
          onClick={handleClick}
          size="large"
        >
          <FontAwesomeIcon icon={faGrid} />
        </IconButton>
        <Button
          color="inherit"
          component={Link}
          size="large"
          startIcon={<SmartToyTwoToneIcon sx={{ mr: 1 }} />}
          to={`/platforms/${currentPlatform.slug}`}
        >
          {currentPlatform.name}
        </Button>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <Box sx={{ display: "grid", gap: 1, p: 2 }}>
          {platforms.map((platform) => (
            <Button
              component={Link}
              key={platform.id}
              onClick={() => handlePlatformClick(platform)}
              size="large"
              startIcon={<FontAwesomeIcon icon={faMessageBot} />}
              to={`/platforms/${platform.slug}`}
              variant={
                platform.id === currentPlatform.id ? "contained" : "outlined"
              }
            >
              {platform.name}
            </Button>
          ))}
          <Box sx={{ my: 1, borderBottom: 1, borderColor: "divider" }} />
          <Button
            color="primary"
            component={Link}
            disabled
            size="large"
            startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            to="/platforms/create"
            variant="outlined"
          >
            Create
          </Button>
        </Box>
      </Popover>
    </div>
  );
});
