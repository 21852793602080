import { Feature } from "@monorepo/constants";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import { format, parseISO } from "date-fns";
import { logout } from "modules/authentication";
import {
  selectCurrentAccountId,
  selectCurrentPlatformId
} from "modules/common/selectors";
import { apiCall } from "modules/core";
import { normalize } from "normalizr";
import { PlatformsService, PlatformUsageEntity } from "openapi";
import { platformUsageSchema } from "store";
import { RootState } from "types";
import {
  NormalizedPlatformUsageEntity,
  PlainPlatformUsageEntity
} from "../entities";

const { platformUsagesControllerFindAll } = PlatformsService;

export const findAllPlatformUsages = createAsyncThunk(
  "platformUsages/findAll",
  async (_, { rejectWithValue }) =>
    await apiCall(platformUsagesControllerFindAll, rejectWithValue)
);

// Entity adapter
const entityAdapter = createEntityAdapter<PlainPlatformUsageEntity>({
  sortComparer: (entityA, entityB) =>
    entityA.createdAt.localeCompare(entityB.createdAt)
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "platformUsageEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findAllPlatformUsages.fulfilled, (state, action) => {
      const normalizedData = normalize<
        PlatformUsageEntity,
        { platformUsages: Record<string, NormalizedPlatformUsageEntity> }
      >(action.payload.data, [platformUsageSchema]);

      const nextEntities = Object.values(
        normalizedData.entities.platformUsages || []
      );

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const platformUsageEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectPlatformUsages,
  selectEntities: selectPlatformUsageEntities,
  selectById: selectPlatformUsageById
} = entityAdapter.getSelectors(
  (state: RootState) => state.platformUsageEntities
);

// Custom selector
export const selectChatCompletionUsages = createSelector(
  selectPlatformUsages,
  (platformUsages) =>
    platformUsages.filter(
      (platformUsage) => platformUsage.feature === Feature.ChatCompletion
    )
);

export const selectAppUsages = createSelector(
  selectPlatformUsages,
  (platformUsages) =>
    platformUsages.filter(
      (platformUsage) => platformUsage.feature === Feature.App
    )
);

export const selectTokenUsagesByMonth = createSelector(
  selectCurrentPlatformId,
  selectCurrentAccountId,
  selectChatCompletionUsages,
  (platformId, accountId, usages) => {
    const tenantUsages = usages.filter(
      (usage) =>
        usage.platformId === platformId && usage.accountId === accountId
    );

    // Group usages by month
    const monthlyUsages = tenantUsages.reduce((acc, usage) => {
      const date = parseISO(usage.createdAt);
      const month = format(date, "MMM");

      // Sum both prompt and completion tokens
      const totalTokens =
        (usage.promptTokens || 0) + (usage.completionTokens || 0);
      acc[month] = (acc[month] || 0) + totalTokens;
      return acc;
    }, {} as Record<string, number>);

    // Transform to the required format
    return Object.entries(monthlyUsages).map(([month, tokens]) => ({
      month,
      tokens
    }));
  }
);
