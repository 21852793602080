import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { findCurrentAccount, logout } from "modules/authentication";
import { normalize } from "normalizr";
import { MembershipEntity } from "openapi";
import { accountSchema } from "store";
import { RootState } from "types";
import { NormalizedMembershipEntity, PlainMembershipEntity } from "../entities";

// Entity adapter
const entityAdapter = createEntityAdapter<PlainMembershipEntity>({
  sortComparer: (entityA, entityB) => entityB.id - entityA.id
});

const initialState = entityAdapter.getInitialState();

// Define the slice
const slice = createSlice({
  name: "membershipEntities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);

    builder.addCase(findCurrentAccount.fulfilled, (state, action) => {
      const normalizedData = normalize<
        MembershipEntity,
        { memberships: Record<string, NormalizedMembershipEntity> }
      >(action.payload, [accountSchema]);

      const nextEntities = Object.values(
        normalizedData.entities.memberships || []
      );

      entityAdapter.upsertMany(state, nextEntities);
    });
  }
});

export const membershipEntitiesReducer = slice.reducer;

// Export the standard reducers and the reducer function
export const {
  selectAll: selectMemberships,
  selectEntities: selectMembershipEntities,
  selectById: selectMembershipById
} = entityAdapter.getSelectors((state: RootState) => state.membershipEntities);

// Custom selector
