import { AppBar, Container } from "@mui/material";
import { selectIsLoggedIn } from "modules/common/selectors";
import { useAppSelector, withWrapper } from "modules/core";
import { GuestHeader } from "./GuestHeader";
import { UserHeader } from "./UserHeader";

export const Header = withWrapper(() => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        {isLoggedIn ? <UserHeader /> : <GuestHeader />}
      </Container>
    </AppBar>
  );
});
