import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid2 as Grid,
  LinearProgress,
  Typography
} from "@mui/material";
import { useAppSelector } from "modules/core";
import { getUsageColor } from "../services";
import { selectPlatformAppsWithUsage } from "../slices";

export const InstalledApps = () => {
  const platformApps = useAppSelector(selectPlatformAppsWithUsage);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Installed Apps - Platform Level
        </Typography>
        <Grid container spacing={2}>
          {platformApps.map((app) => (
            <Grid key={app.name} size={{ xs: 12, md: 4 }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">{app.name}</Typography>
                  <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                    <Chip label={app.state} size="small" color="success" />
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Account Usage
                  </Typography>
                  <LinearProgress
                    color={getUsageColor(app.usage)}
                    variant="determinate"
                    value={app.usage}
                    sx={{ height: 8, borderRadius: 4 }}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {app.usage}% utilized
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
