export enum SubscriptionState {
  /**
   * User is in good standing and has access to the subscription.
   */
  Active = "Active",

  /**
   * User has canceled but still has access until the end of the billing period.
   * After that, it typically transitions to Expired.
   */
  Cancelled = "Cancelled",

  /**
   * Payment issue occurred, but the user still has access while the system (or PayPal) retries the payment method.
   * ("GracePeriod" might be called "Grace" in some systems.)
   */
  GracePeriod = "GracePeriod",

  /**
   * Payment issue occurred and the user no longer has access.
   * The system (or PayPal) may still be retrying the payment method.
   */
  OnHold = "OnHold",

  /**
   * User paused their access; no access until they resume.
   */
  Paused = "Paused",

  /**
   * User no longer has access to the subscription (billing period ended, or the subscription was canceled and the grace period expired).
   */
  Expired = "Expired"
}
