import { PlatformEntity } from "openapi";

export interface NormalizedPlatformEntity
  extends Omit<PlatformEntity, "platformApps"> {
  platformApps: number[];
}

export interface PlainPlatformEntity extends NormalizedPlatformEntity {}

export const extractPlatformPlainData = ({
  ...platform
}: NormalizedPlatformEntity): PlainPlatformEntity => platform;
